"use client";
import SkeletonLoader from "@/components/ui/SkeletonLoader";
import {
  ARBITRUM_MAINNET,
  BASE_MAINNET,
  ETHEREUM_MAINNET,
  OPTIMISM_MAINNET,
  POLYGON_MAINNET,
} from "@/constants/chains";
import {
  cacheNftsForSellModal,
  getCachedNftsForSellModal,
} from "@/server/actions/user/cache";
import { getWalletNfts } from "@/server/actions/user/ownedNfts";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Tokens from "./Tokens";

type Props = {
  walletAddress: string;
  setStep: Dispatch<SetStateAction<number>>;
  setNft: Dispatch<any>;
  step: number;
  page: string | undefined;
};

const FetchTokens = ({ walletAddress, setStep, step, setNft, page }: Props) => {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNfts = async () => {
      const LIMIT = 100;
      const supportedNetworks = [
        ETHEREUM_MAINNET,
        POLYGON_MAINNET,
        BASE_MAINNET,
        OPTIMISM_MAINNET,
        ARBITRUM_MAINNET,
      ];

      try {
        const nftsFromCache = await getCachedNftsForSellModal(walletAddress);
        console.log("nftsFromCache: ", nftsFromCache);
        if (nftsFromCache) {
          // @ts-ignore
          setNfts(nftsFromCache);
          setLoading(false);
          return;
        }
        const nftsByNetwork = await Promise.all(
          supportedNetworks.map((network) =>
            getWalletNfts(walletAddress, LIMIT, network.name)
          )
        );
        const nfts = nftsByNetwork.flatMap((result) => result?.ownedNfts || []);
        // @ts-ignore
        setNfts(nfts);
        await cacheNftsForSellModal(walletAddress, nfts);
      } catch (error) {
        console.error("Error fetching NFTs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNfts();
  }, [walletAddress]);

  return loading ? (
    <SkeletonLoader containerShape="mb-6 w-full h-[300px] min-h-[200px] rounded-lg" />
  ) : (
    <Tokens nfts={nfts} setStep={setStep} step={step} setNft={setNft} />
  );
};

export default FetchTokens;
