import SkeletonLoader from "@/components/ui/SkeletonLoader";
import { Dispatch, SetStateAction, Suspense } from "react";
import NFTCardUiV2 from "./NFTCard";
import IconBack from "@/components/ui/Icons/IconBack";

type Props = {
  nfts: any[];
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
  setNft: Dispatch<any>;
};

export default function Tokens({ nfts, setStep, step, setNft }: Props) {
  const handleClick = (e, nft) => {
    e.preventDefault();
    console.log("NFT: ", nft);
    setNft(nft);
    setStep(2);
  };

  return (
    <div className="xl:flex-1 w-full">
      <div className="h-[24px] sm:h-[36px]" />
      {nfts && nfts.length > 0 ? (
        <div>
          <div className="min-auto overflow-y-scroll scrollbar-hide">
            <div className="flex gap-x-4 items-start w-full relative">
              <div className="w-full">
                <div className="masonryColumns">
                  <>
                    {Array.isArray(nfts) &&
                      nfts.map((nft, index) => (
                        <Suspense
                          key={index}
                          fallback={
                            <SkeletonLoader containerShape="mb-6 w-full h-[300px] min-h-[200px] rounded-lg" />
                          }
                        >
                          <div onClick={(e) => handleClick(e, nft)}>
                            <NFTCardUiV2
                              network={nft.network}
                              address={nft.contract.address}
                              tokenId={nft.tokenId}
                              tokenName={nft.name}
                              nftImage={nft.image.cachedUrl}
                              // animationUrl,
                              collectionImage={
                                nft.contract.openSeaMetadata.imageUrl
                              }
                              collectionName={
                                nft.contract.openSeaMetadata.collectionName ||
                                nft.contract.name
                              }
                              step={step}
                            />
                          </div>
                        </Suspense>
                      ))}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-start sm:justify-center tracking-wide items-center text-center my-6 gap-y-3">
          <div className="text-xl">Inventory is empty 😢</div>
          <div className="text-2xl">No treasures in the corral yet!</div>
        </div>
      )}
    </div>
  );
}
