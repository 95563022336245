type Props = {
  network: string;
  address: string;
  collectionName: string;
};

export default function CollectionName({
  network,
  address,
  collectionName,
}: Props) {
  return (
    <div className=" text-light-gray sm:font-500 sm:text-white flex items-center w-full">
      <div className="line-clamp-2 w-full text-12 max-w-[100%] font-400 ">
        {collectionName}
      </div>
    </div>
  );
}
