type Props = {
  network: string;
  address: string;
  collectionName: string;
  collectionImage: string;
};

export default function CollectionImage({
  network,
  address,
  collectionName,
  collectionImage,
}: Props) {
  return (
    <div
      className=" flex-shrink-0 items-center justify-center rounded-full sm:flex md:border-4 
         md:border-white/10"
    >
      <img
        src={collectionImage}
        alt={collectionName || "collection"}
        className="pointer-events-none max-h-[36px] min-h-[36px] w-[36px] h-[36px] rounded-full object-cover"
      />
    </div>
  );
}
