import {
  imageFileTypes,
  modelFileTypes,
  audioFileTypes,
  videoFileTypes,
} from "@/constants/media";

import dynamic from "next/dynamic";
import mime from "mime-types";
import { useEffect, useState, useMemo } from "react";
import LoadingDots from "@/components/ui/Loading/LoadingDots";
import SkeletonLoader from "@/components/ui/SkeletonLoader";

export const SkeletonLoaderMedia = () => (
  <SkeletonLoader containerShape="h-[300px] w-full rounded-xl md:h-[600px]" />
);

type Props = {
  src: string;
  fallback?: string;
  alt?: string;
  className?: string; // Corrected prop name from 'class' to 'className'
  nftImageFileType?: string;
  nftCached?: string;
};

export default function MediaWrangler({
  src,
  nftImageFileType,
  fallback,
  nftCached,
}: Props) {
  const [fileType, setFileType] = useState("image");
  const [imgSrc, setImgSrc] = useState(src);
  const imgNotFound = "/image-not-found.png";

  const isValidUrl = useMemo(
    () => (url: string) => {
      try {
        new URL(url);
        return true;
      } catch {
        return false;
      }
    },
    []
  );

  useEffect(() => {
    const validSrc = isValidUrl(src)
      ? src
      : nftCached && isValidUrl(nftCached)
      ? nftCached
      : imgNotFound;
    setImgSrc(validSrc);

    const determineFileType = (extension: string | null) => {
      if (!extension) return "image";
      if (imageFileTypes.includes(extension) || extension === "svg")
        return extension === "svg" ? "svg" : "image";
      if (modelFileTypes.includes(extension)) return "model";
      if (audioFileTypes.includes(extension)) return "audio";
      if (videoFileTypes.includes(extension)) return "video";
      return "image";
    };

    const fileExtension = imgSrc.split(".").pop();
    if (fileExtension) {
      setFileType(determineFileType(fileExtension));
    } else if (nftImageFileType) {
      const nftFileType = nftImageFileType.split("/")[1];
      setFileType(determineFileType(nftFileType));
    } else {
      getFileTypeFromUrl(imgSrc).then((ext) =>
        setFileType(determineFileType(ext))
      );
    }
  }, [src, nftCached, imgNotFound, isValidUrl]);

  const getFileTypeFromUrl = useMemo(
    () =>
      async (url: string): Promise<string | null> => {
        try {
          const response = await fetch(url, { method: "HEAD" });
          const contentType = response.headers.get("content-type");
          return contentType ? mime.extension(contentType) : null;
        } catch (error) {
          console.error("Error fetching content type:", error);
          return null;
        }
      },
    []
  );

  const handleError = () => {
    setImgSrc(imgSrc === nftCached ? imgNotFound : nftCached || imgNotFound);
  };

  if (!imgSrc) {
    return (
      <img
        src={imgNotFound}
        width={500}
        height={500}
        alt="NFT Image"
        className="h-full w-full object-contain object-center sm:rounded-lg"
      />
    );
  }

  switch (fileType) {
    case "image":
      return (
        <img
          src={imgSrc}
          // width={500}
          // height={500}
          alt="NFT Image"
          className="group-hover:opacity-50 h-full w-full object-contain object-center sm:rounded-lg max-h-screen"
          onError={handleError}
        />
      );
    case "svg":
      return (
        <embed
          src={imgSrc}
          type="image/svg+xml"
          className="h-full group-hover:opacity-50  w-full object-cover object-center sm:rounded-lg"
        />
      );
    // case "model":
    //   return <GoogleModel model={imgSrc} />;
    case "audio":
      return <audio controls src={imgSrc} className="w-full h-full" />;
    case "video":
      return (
        <>
          <video
            src={imgSrc}
            className="hidden lg:flex h-full w-full object-contain object-center sm:rounded-lg"
            autoPlay
            muted
            loop
            playsInline // Required for iOS inline video
            // controls
            poster={nftCached || fallback}
            onError={handleError}
          />
          <img
            src={
              nftCached && nftCached.length > 0
                ? nftCached
                : fallback && fallback.length > 0
                ? fallback
                : imgNotFound
            }
            alt="NFT Image"
            className="lg:hidden group-hover:opacity-50  h-full w-full object-contain object-center sm:rounded-lg max-h-screen"
            onError={handleError}
          />
        </>
      );
    case "text/html":
      return (
        <iframe
          src={imgSrc}
          sandbox="allow-same-origin allow-scripts"
          frameBorder="0"
          allowFullScreen
          className="h-auto min-h-[500px] w-full object-cover object-center"
        ></iframe>
      );
    default:
      return (
        <img
          src={imgNotFound}
          width={500}
          height={500}
          alt="NFT Image"
          className="h-full w-full object-contain object-center sm:rounded-lg"
        />
      );
  }
}
