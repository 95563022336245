"use client";
import Login from "@/components/magic/auth/Login";
import { useMagic } from "@/context/MagicProvider";
import { logoutUser } from "@/server/actions/user/auth/logout";
import { getBasicInfo } from "@/server/actions/user/getBasicInfoJWT";
import { logout } from "@/utils/common";
import { motion } from "framer-motion";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { create } from "zustand";
import Alert from "../../Alert";
import ContactUs from "../../ContactForm";
import IconCloseX from "../../Icons/IconCloseX";
import IconMenu from "../../Icons/IconMenu";
import LoadingDots from "../../Loading/LoadingDots";
import Modal from "../../Modal";
import { useSellModalStateStore } from "../../SellModal/SellForm";
import {
  fadeInVariant,
  hideNavItemsVariant,
  liVariant,
  mobileMenuVariant,
  ulVariant,
} from "./animation";
import { FORUM, LEGAL_POLICIES } from "./static-links";
import styles from "./style.module.scss";

interface MobileMenuState {
  mobileMenu: boolean;
  setMobileMenu: (searchModal: boolean) => void;
}

export const useMobileMenuStore = create<MobileMenuState>((set) => ({
  mobileMenu: false,
  setMobileMenu: (mobileMenu) => set({ mobileMenu }),
}));

const MobileMenu = () => {
  const { mobileMenu, setMobileMenu } = useMobileMenuStore();
  const { magic } = useMagic();
  const router = useRouter();

  const [loadingLikes, setLoadingLikes] = useState(false);
  const [loadingMyInventory, setLoadingMyInventory] = useState(false);

  const checkUserLoggedIn = async () => {
    const loggedIn = await magic?.user?.isLoggedIn();
    return loggedIn;
  };

  const handleMyInventory = async () => {
    setLoadingMyInventory(true);
    try {
      const basicInfo = await getBasicInfo();
      if (
        basicInfo &&
        basicInfo.userWallet &&
        basicInfo.userWallet.length > 0
      ) {
        router.push(`/profile/${basicInfo.userWallet}`);
      } else {
        const isUserLoggedIn = await checkUserLoggedIn();

        if (isUserLoggedIn) {
          const userData = await magic?.user.getMetadata();
          const publicAddress = userData?.publicAddress || "";
          router.push(`/profile/${publicAddress}`);
        } else {
          router.push("/search");
        }
      }
    } catch (error) {
      console.error("Error navigating to inventory:", error);
    } finally {
      setMobileMenu(false);
      setLoadingMyInventory(false);
    }
  };

  const handleLikes = async () => {
    setLoadingLikes(true);
    try {
      const basicInfo = await getBasicInfo();
      if (
        basicInfo &&
        basicInfo.userWallet &&
        basicInfo.userWallet.length > 0
      ) {
        router.push(`/likes`);
      } else {
        const isUserLoggedIn = await checkUserLoggedIn();
        if (isUserLoggedIn) {
          router.push(`/likes`);
        } else {
          setLoginModal(true);
        }
      }
    } catch (error) {
      console.error("Error navigating to likes:", error);
    } finally {
      setMobileMenu(false);
      setLoadingLikes(false);
    }
  };

  const [contactModal, setContactModal] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertType, setAlertType] = useState("default");
  const [loginModal, setLoginModal] = useState(false);

  const handleContactUs = () => {
    setMobileMenu(false);
    setContactModal(true);
  };

  const handleLinkClick = (href: string) => {
    router.push(href);
    setMobileMenu(false);
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      await logout(magic);
      window.open("/", "_self");
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const { open, setOpen } = useSellModalStateStore();
  const handleClickSell = () => {
    setMobileMenu(false);
    setOpen(true);
  };

  const handleClickGiveaway = () => {
    setMobileMenu(false);
    window.open("/rewards/follow-x-july-2024");
  };

  // const { setOpen } = useMintSlideOverStore();

  // const handleClickCreate = () => {
  //   setOpen(true);
  //   setMobileMenu(false);
  // };

  const MOBILE_NAV_ITEMS = [
    {
      id: 0,
      navTitle: "My Inventory",
      onclick: handleMyInventory,
    },
    {
      id: 1,
      navTitle: "Sell",
      onclick: handleClickSell,
    },
    // {
    //   id: 2,
    //   navTitle: "Giveaways",
    //   onclick: handleClickGiveaway,
    // },
    {
      id: 3,
      navTitle: "Likes",
      onclick: handleLikes,
    },
    {
      id: 4,
      navTitle: "Rewards",
      href: "/rewards",
    },
    {
      id: 5,
      navTitle: "Contact Us",
      onclick: handleContactUs,
    },
  ];

  return (
    <>
      <motion.button
        variants={hideNavItemsVariant}
        onClick={() => setMobileMenu(true)}
        className="cursor-pointer sm:hidden"
      >
        <IconMenu />
      </motion.button>
      <motion.nav
        initial="closed"
        animate={mobileMenu ? "opened" : "closed"}
        className="sm:hidden"
      >
        <div className={styles.menuContainer}></div>
        <motion.div variants={mobileMenuVariant} className={styles.mobileMenu}>
          <motion.button
            variants={fadeInVariant}
            onClick={() => setMobileMenu(false)}
          >
            <IconCloseX />
          </motion.button>
          <motion.ul variants={ulVariant}>
            {MOBILE_NAV_ITEMS.map((navItem) => (
              <motion.li whileTap={{ scale: 0.95 }} key={navItem.id}>
                <motion.div
                  variants={liVariant}
                  onClick={
                    navItem.href
                      ? () => handleLinkClick(navItem.href)
                      : navItem.onclick
                  }
                >
                  {navItem.id === 1 && loadingMyInventory ? (
                    <LoadingDots />
                  ) : navItem.id === 2 && loadingLikes ? (
                    <LoadingDots />
                  ) : (
                    navItem.navTitle
                  )}
                </motion.div>
              </motion.li>
            ))}
          </motion.ul>
          <div className="mt-8" />
          <motion.div variants={fadeInVariant} className={styles.contact}>
            <div onClick={handleLogout}>Log out</div>
            {FORUM.map((item) => (
              <div
                key={item.navTitle}
                onClick={() => handleLinkClick(item.href)}
              >
                {item.navTitle}
              </div>
            ))}
          </motion.div>
          <div className="mt-6" />
          <motion.div variants={fadeInVariant} className={styles.contact}>
            {LEGAL_POLICIES.map((policy) => (
              <div
                key={policy.navTitle}
                onClick={() => handleLinkClick(policy.href)}
              >
                {policy.navTitle}
              </div>
            ))}
          </motion.div>
        </motion.div>
      </motion.nav>
      <Modal open={contactModal} setOpen={setContactModal} width="sm:w-[80%]">
        <ContactUs
          setContactModal={setContactModal}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setAlertType={setAlertType}
        />
      </Modal>
      <Alert
        open={snackbar}
        close={() => setSnackbar(false)}
        message={snackbarMessage}
        type={alertType}
      />
      <Modal
        open={loginModal}
        setOpen={setLoginModal}
        width="sm:w-[400px] w-full"
      >
        <Login setLoginModal={setLoginModal} />
      </Modal>
    </>
  );
};

export default MobileMenu;
