type Props = {
  network: string;
  address: string;
  tokenName?: string | null | undefined;
  tokenId: string;
};

export default function NftName({
  network,
  address,
  tokenId,
  tokenName,
}: Props) {
  let name = tokenName;
  if (!name || name === undefined || name.length === 0) {
    name = `Token # ${tokenId}`;
  }
  return (
    <div className="flex sm:font-500 text-white ">
      <div className="flex mt-3 xl:mt-0 text-14  xl:max-w-[180px] ">
        <span className=" line-clamp-2">{name}</span>
      </div>
    </div>
  );
}
