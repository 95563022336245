import NftClientImage from "./NftClientImage";

type Props = {
  nftImage: string | null | undefined;
  nftImageFileType?: any;
  animationUrl?: string;
  tokenUri?: string;
  nftCached?: string;
};

export default function NftImage({
  nftImage,
  nftImageFileType,
  animationUrl,
  tokenUri,
  nftCached,
}: Props) {
  return (
    <NftClientImage
      image={nftImage}
      animationUrl={animationUrl}
      nftCached={nftCached}
      nftImageFileType={nftImageFileType}
    />
  );
}
