export const LEGAL_POLICIES = [
  {
    navTitle: "Terms",
    href: "/policies/terms",
  },
  {
    navTitle: "Privacy",
    href: "/policies/privacy",
  },
  {
    navTitle: "Copyright",
    href: "/policies/copyright",
  },
];

export const FORUM = [
  {
    navTitle: "forum",
    href: "https://help.rodeyo.com/",
  },
];
