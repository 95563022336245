"use client";

import { useMagic } from "@/context/MagicProvider";
import { Tooltip } from "@mui/material";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { ImSpinner9 } from "react-icons/im";
import Login from "../../magic/auth/Login";
import IconSettings from "../Icons/IconSettings";
import Modal from "../Modal";
import { useAccount } from "wagmi";

export default function Settings() {
  const [goToSettings, setGoToSettings] = useState(false);
  const [login, setLoginModal] = useState(false);
  const router = useRouter();
  const { magic } = useMagic();
  const { isConnected } = useAccount();

  const handleSettingsClick = async () => {
    setGoToSettings(true);
    if (isConnected) {
      router.push("/settings");
      setGoToSettings(false);
      return;
    }
    const loggedIn = await magic?.user?.isLoggedIn();
    if (loggedIn === true) {
      router.push("/settings");
    } else {
      setLoginModal(true);
    }
    setGoToSettings(false);
  };
  return (
    <>
      <Tooltip title="Settings" arrow placement="right">
        <button className="mx-auto " onClick={handleSettingsClick}>
          {!goToSettings ? (
            <IconSettings />
          ) : (
            <ImSpinner9 className="text-dark-pri/70 animate-spin w-6 h-6" />
          )}
        </button>
      </Tooltip>
      <Modal open={login} setOpen={setLoginModal} width="sm:w-[400px] w-full">
        <Login setLoginModal={setLoginModal} />
      </Modal>
    </>
  );
}
