export const IconWallet = ({
  className,
  color,
}: {
  className?: string;
  color?: string;
}) => (
  <svg
    className={className}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5906 6.26318L20.6389 6.26318C21.0531 6.26318 21.3889 6.59897 21.3889 7.01318V12.3957C21.3889 12.8099 21.0531 13.1457 20.6389 13.1457H16.5906C14.6907 13.1446 13.1496 11.6055 13.1484 9.70492C13.1484 7.80351 14.6911 6.26435 16.5906 6.26318ZM19.8889 7.76318H16.5909C15.5178 7.76394 14.6487 8.63279 14.6484 9.704M19.8889 7.76318V11.6457H16.5911C15.5182 11.6451 14.6492 10.7762 14.6484 9.704"
      fill={color ? color : "currentColor"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9869 9.64282C15.9869 9.22861 16.3227 8.89282 16.7369 8.89282H17.0486C17.4628 8.89282 17.7986 9.22861 17.7986 9.64282C17.7986 10.057 17.4628 10.3928 17.0486 10.3928H16.7369C16.3227 10.3928 15.9869 10.057 15.9869 9.64282Z"
      fill={color ? color : "currentColor"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.74766 1.75C4.26372 1.75 2.25 3.76372 2.25 6.24766V13.4247C2.25 15.9086 4.26372 17.9224 6.74766 17.9224H15.3911C17.875 17.9224 19.8888 15.9086 19.8888 13.4247V6.24766C19.8888 3.76372 17.875 1.75 15.3911 1.75H6.74766ZM0.75 6.24766C0.75 2.9353 3.4353 0.25 6.74766 0.25H15.3911C18.7035 0.25 21.3888 2.9353 21.3888 6.24766V13.4247C21.3888 16.7371 18.7035 19.4224 15.3911 19.4224H6.74766C3.4353 19.4224 0.75 16.7371 0.75 13.4247V6.24766Z"
      fill={color ? color : "currentColor"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28561 5.53809C5.28561 5.12387 5.6214 4.78809 6.03561 4.78809H11.4346C11.8488 4.78809 12.1846 5.12387 12.1846 5.53809C12.1846 5.9523 11.8488 6.28809 11.4346 6.28809H6.03561C5.6214 6.28809 5.28561 5.9523 5.28561 5.53809Z"
      fill={color ? color : "currentColor"}
    />
  </svg>
);

export default IconWallet;
