"use client";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import React from "react";
import IconHelp from "../Icons/IconHelp";

export default function Help() {
  return (
    <Tooltip title="Help" arrow placement="right">
      <a
        className="mx-auto "
        href="https://help.rodeyo.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconHelp />
      </a>
    </Tooltip>
  );
}
