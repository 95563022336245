"use client";


import React from "react";
import MediaWrangler from "./Media";

type Props = {
  image: string | null | undefined;
  animationUrl?: string;
  nftImageFileType?: string;
  nftCached?: string;
};

const NftClientImage: React.FC<Props> = ({
  image,
  animationUrl,
  nftImageFileType,
  nftCached,
}) => {
  return (
    <MediaWrangler
      src={image || animationUrl || ""}
      fallback={image || ""}
      nftImageFileType={nftImageFileType}
      nftCached={nftCached}
    />
  );
};

export default NftClientImage;
