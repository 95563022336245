"use client";
import React, { createContext, useContext, useEffect, useState } from "react";
import Web3 from "web3";

import { useMagic } from "./MagicProvider";
import {
  MAGIC_ERC721_MINT_ABI,
  MAGIC_ERC721_MINT_ADDRESS,
} from "@/constants/contracts";

// Define the structure of the Web3 context state
type Web3ContextType = {
  web3: Web3 | null;
  initializeWeb3: () => void;
  contract: any;
  isAccountChanged: boolean;
};

// Create the context with default values
const Web3Context = createContext<Web3ContextType>({
  web3: null,
  initializeWeb3: () => {},
  contract: null,
  isAccountChanged: false,
});

// Custom hook to use the Web3 context
export const useWeb3 = () => useContext(Web3Context);

// Provider component to wrap around components that need access to the context
export const Web3Provider = ({ children }: { children: React.ReactNode }) => {
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [contract, setContract] = useState<any | null>(null);
  const [isAccountChanged, setIsAccountChanged] = useState<boolean>(false);
  const magic = useMagic();

  useEffect(() => {
    let provider;

    const initialize = async () => {
      try {
        if (!magic || !MAGIC_ERC721_MINT_ADDRESS) {
          console.error("Magic instance or CONTRACT_ADDRESS is not available.");
          return;
        }

        provider = await magic.magic?.wallet.getProvider();
        if (!provider) {
          // console.error("Provider is not available.");
          return;
        }

        const web3Instance = new Web3(provider);

        // Setup event listeners for provider
        provider.on("accountsChanged", () =>
          setIsAccountChanged((state) => !state)
        );
        provider.on("chainChanged", async () => window.location.reload());

        const contractInstance = new web3Instance.eth.Contract(
          MAGIC_ERC721_MINT_ABI,
          MAGIC_ERC721_MINT_ADDRESS
        );
        setWeb3(web3Instance);
        setContract(contractInstance);
      } catch (error) {
        console.error("Failed to initialize web3 or contract", error);
      }
    };

    initialize();

    // Cleanup function
    return () => {
      // Check if provider is set and if it has the removeListener method
      if (provider && provider.removeListener) {
        provider.removeListener("accountsChanged");
        provider.removeListener("chainChanged");
      }
    };
  }, [magic, MAGIC_ERC721_MINT_ADDRESS]); // Dependencies

  return (
    <Web3Context.Provider
      value={{
        web3,
        initializeWeb3: () => {}, // Placeholder for your initializeWeb3 function if needed
        contract,
        isAccountChanged,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
